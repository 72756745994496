<template>
  <div>
    <div class="chart-h5-wrap">
      <div
        v-show="!showTechniciansDetailFlag"
        class="chart-tab"
      >
        <span
          class="chart-tab-item"
          :class="{'active': chartType === '1'}"
          @click="tabChange('1')"
          :history="false"
        >今日</span>
        <span
          class="chart-tab-item"
          :class="{'active': chartType === '2'}"
          @click="tabChange('2')"
          :week="false"
        >本周</span>
        <span
          class="chart-tab-item"
          :class="{'active': chartType === '3'}"
          @click="tabChange('3')"
        >本月</span>
        <span
          class="chart-tab-item"
          :class="{'active': chartType === '5'}"
          @click="tabChange('5')"
        >自定义</span>
      </div>
      <div
        v-show="!showTechniciansDetailFlag"
        class="chart-body"
      >
        <div
          class="screen"
          v-if="chartType === '5'"
        >
          <div class="screen-date">
            <cube-button
              @click="showDatePicker(true)"
              class="date-width"
            >{{formatDate(this.startDate, 'YYYY-MM-DD')}}</cube-button>
            <i>|</i>
            <cube-button
              @click="showDatePicker(false)"
              class="date-width"
            >{{formatDate(this.endDate, 'YYYY-MM-DD')}}</cube-button>
          </div>
          <cube-button
            class="date-btn"
            @click="research()"
          >筛选</cube-button>
        </div>
      </div>
      <div>
        <div class="chart-h5-wrap">
          <technicians-detail
            v-if="showTechniciansDetailFlag"
            :detailData="activeTechnician"
            :requestParams="requestParams"
            :hideNavBarFlag="true"
            @chart-close='closeTechniciansDetail'
            ref='techniciansDetail'
          ></technicians-detail>
          <div
            style="background:#fff;"
            v-show="!showTechniciansDetailFlag"
          >
            <div>
              <div class="section-map">
                业绩统计
                <img
                  class="toolip-icon"
                  src="@/assets/images/dealer/doubt.png"
                  alt=""
                  @click="showTip('top')"
                >
                <p class="tip-eg">
                  <cube-tip
                    ref="tip2"
                    :direction="direction"
                    :style="tipStyle"
                    @close="close"
                    @click="clickHandler"
                  >
                    <div>按工单里的工时费选择技师来统计技师业绩</div>
                  </cube-tip>
                </p>
              </div>
              <div>
                <ul class="sub-box">
                  <li><span>工时统计:</span>￥{{(resData.taskAmount || 0) | money}}</li>
                  <li><span>统计人数:</span>{{resData.staffCount || 0 }} 人</li>
                </ul>
              </div>
            </div>
            <div class="fill-block"></div>
            <div>
              <div class="section-title">业绩明细</div>
              <ul class="list-box">
                <li><span>技师</span> <span>工时总额<i>(元)</i></span></li>
                <li
                  v-for="(item , index) in resData.staffTaskVOList"
                  :key="index"
                  @click="showTechniciansDetail(item)"
                >
                  <span>{{item.name}}</span>
                  <span>{{item.taskAmount | money}}</span>
                  <img
                    src="@/assets/images/dealer/arrow.png"
                    alt=""
                  >
                </li>
              </ul>
            </div>
            <!-- <div style="height:700px;"></div> -->
          </div>
          <p class="no-more">没有更多了</p>
        </div>
        <cube-loading
          :size="28"
          v-if="loadingFlag"
          class="loading"
        ></cube-loading>
      </div>
    </div>
    <div
      v-if="noDataFlag && !moreMode"
      class="metadata"
    >
      暂无数据
    </div>
    <cube-loading
      :size="28"
      v-if="loadingFlag"
      class="loading"
    ></cube-loading>
  </div>
</template>

<script>
import { getStockStatus, getDelalerPerformanceSummary, getTechniciansStatisticsNew } from 'api/dealer'
import { setSessionStorage } from 'utils/store'
import techniciansDetail from 'components/business-chart/technicians-detail'
import moment from 'moment'
export default {
  name: 'reportsStatistics',
  components: {
    techniciansDetail
  },
  data () {
    return {
      more: false,
      profit: false,
      stock: false,
      vehicle: false,
      direction: '',
      tipStyle: '',
      eChart: null,
      showBlankChart: true,
      chartType: '1',
      chartsData: [],
      resData: {},
      summaryData: {},
      stockOpenFlag: false,
      moreMode: '',
      endDate: new Date(),
      startDate: new Date((new Date() - 3600 * 1000 * 24 * 364)),
      loadingFlag: true,
      noDataFlag: false,
      activeTechnician: {},
      showTechniciansDetailFlag: false
    }
  },
  computed: {
    requestParams () {
      let params = {
        dimension: parseInt(this.chartType)
      }
      if (this.chartType === '5') {
        params.endMonth = this.formatDate(this.endDate, 'YYYY-MM-DD' + ' 23:59:59')
        params.startMonth = this.formatDate(this.startDate, 'YYYY-MM-DD' + ' 00:00:00')
      }
      return params
    }
  },
  methods: {
    confirmClose () {
      this.moreMode = null
    },
    // 获取是否开启关闭库存
    getStockOpenStatus () {
      getStockStatus().then((res) => {
        if (res) {
          if (res.data.result !== 0) return
          this.stockOpenFlag = !!res.data.data
        }
      })
    },
    showDatePicker (startFlag) {
      console.log('startFlag', startFlag)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 730)
      let configData = {
        title: '选择时间',
        min: new Date(start),
        // max: new Date(),
        columnCount: 3,
        value: startFlag ? this.startDate : this.endDate,
        onSelect: startFlag ? this.selectStartHandle : this.selectEndHandle,
        onCancel: this.cancelHandle
      }
      // 点击起始日期
      if (startFlag) {
        configData.max = this.endDate ? this.endDate : new Date()
      } else {
        configData.max = new Date()
      }
      this.datePicker = this.$createDatePicker(configData)
      this.datePicker.show()
    },
    selectEndHandle (date, selectedVal, selectedText) {
      this.endDate = date
    },
    selectStartHandle (date, selectedVal, selectedText) {
      this.startDate = date
    },
    isInOneYear () {
      const validFlag = ((this.endDate.getTime() - this.startDate.getTime()) <= 3600 * 1000 * 24 * 365)
      return validFlag
    },
    cancelHandle () {
    },
    showTip (direction) {
      this.direction = direction
      this.$refs.tip2.show()
      switch (direction) {
        case 'top':
          this.tipStyle = 'left: -10px; top: 40px;'
          break
      }
    },
    close () {
      console.log('click close button')
    },
    clickHandler () {
      console.log('click tip area')
    },
    formatDate (time, format = 'YYYY-MM-DD') {
      let date = moment(new Date(time)).format(format)
      return (date === 'Invalid date') ? '' : date
    },
    formatYearMonth (date) {
      return this.formatDate(date, 'YYYY-MM')
    },
    formatDay (date) {
      return this.formatDate(date, 'MM-DD')
    },
    formatMonth (month) {
      return this.formatDate(month, 'MM月')
    },
    initData () {
      let token = this.$route.query['token']
      setSessionStorage('token', token)
    },
    requestChartData () {
      this.loadingFlag = true
      getDelalerPerformanceSummary(JSON.stringify(this.requestParams)).then(res => {
        this.loadingFlag = false
        this.noDataFlag = true
        if (res.data.result !== 0) return
        this.summaryData = Object.assign({}, res.data.data)
        this.noDataFlag = false
      })
      this.getTechniciansData()
    },
    research () {
      if (!this.isInOneYear()) {
        return this.$createToast({
          txt: '请选择小于12个月的日期',
          type: 'error'
        }).show()
      }
      this.requestChartData()
    },
    tabChange (dimension) {
      this.chartType = dimension
      this.requestChartData()
    },
    goAppOrderDetailPage () {
      if (this.$route.query['type'] === 'Android') {
        // eslint-disable-next-line
        goBack.orderDetail({ id: '88578248' })
      } else if (this.$route.query['type'] === 'ios') {
        // eslint-disable-next-line
        window.webkit.messageHandlers.orderDetail.postMessage({ id: '88578248' })
      }
    },
    // APP埋点
    doAppTrack (mode) {
      if (this.$route.query['type'] === 'Android') {
        const eventMap = {
          bussiness: 'chartPartEntrance',  // 营业统计入口：
          vehicle: 'chartCarEntrance',  // 车辆统计入口：
          profit: 'chartPartEntrance',   // 配件毛利入口：
          stock: 'chartInventoryEntrance' // 库存统计入口：
        }
        // eslint-disable-next-line
        JsToAndroid.chartBuriedPoint(eventMap[mode] || '')
      } else if (this.$route.query['type'] === 'ios') {
        const eventMap = {
          bussiness: 'turnoverReoprtEntrance',  // 营业统计入口：
          vehicle: 'carReportEntrance',  // 车辆统计入口：
          profit: 'productGrossReportEntrance',   // 配件毛利入口：
          stock: 'stockReportEntrance' // 库存统计入口：
        }
        // eslint-disable-next-line
        window.webkit.messageHandlers.chartBuriedPoint.postMessage(eventMap[mode] || '')
      }
    },
    goDetailPage (mode) {
      this.moreMode = mode
      this.doAppTrack(mode)
    },
    // 刷新列表
    refresh () {
      if (!this.showTechniciansDetailFlag) {
        console.log('技师统计刷新')
        this.getTechniciansData()
      } else {
        this.$refs.techniciansDetail.refresh()
      }
    },
    // 获取技师统计页面
    getTechniciansData () {
      this.loadingFlag = true
      getTechniciansStatisticsNew(JSON.stringify(this.requestParams)).then(res => {
        this.loadingFlag = false
        this.chartsData = []
        this.resData = Object.assign({}, res.data.data)
      })
    },
    showTechniciansDetail (item) {
      this.showTechniciansDetailFlag = true
      this.activeTechnician = Object.assign({}, item)
    },
    closeTechniciansDetail () {
      this.showTechniciansDetailFlag = false
    }
  },
  beforeCreate () {
    setSessionStorage('token', this.$route.query.token)
  },
  mounted () {
    if (this.initData) {
      this.initData()
    }
    let self = this
    window.h5bridge = {
      refresh: () => {
        if (self.moreMode) {
          self.$refs[self.moreMode].refresh()
        } else {
          self.requestChartData()
          self.getStockOpenStatus()
        }
      },
      checkGoBack () {
        // 技师业绩详情时，APP禁止回退
        const cannotAPPGoBack = self.showTechniciansDetailFlag ? 1 : 0
        self.showTechniciansDetailFlag = false
        return cannotAPPGoBack
      }
    }
    this.getStockOpenStatus()
    this.requestChartData()

    this.getTechniciansData()
  }
}
</script>
<style>
.loading {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.cube-btn.cube-btn_active::after,
.cube-btn:active::after {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0 0;
  border: 1px solid #fff !important;
}
.cube-btn.cube-btn_active,
.cube-btn:active {
  background: #fff !important;
  position: relative;
}
.metadata {
  width: 100%;
  line-height: 390px;
  text-align: center;
  color: #999;
  /* background: #e4e4e4; */
  height: auto;
  font-size: 0.4rem;
}
.cube-btn {
  background: #fff;
  height: 0px;
  line-height: 0;
  color: rgba(54, 54, 54, 1);
  float: left;
}
.cube-tip[data-dir="top"] .cube-tip-angle::before {
  margin-top: -4px;
  transform: rotate(0deg);
  background: #fff !important;
}
.cube-tip-angle::before {
  border-right: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  height: 6px;
  width: 6px;
  transform: rotate(314deg) !important;
  -webkit-transform: rotate(45deg);
  /*不加这两个属性三角会比上一个略丑, 大家可以试一下*/
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.cube-tip {
  display: flex;
  z-index: 10;
  position: absolute;
  padding: 10px 38px 10px 16px;
  max-height: 60px;
  font-size: 12px;
  color: rgba(54, 54, 54, 1);
  background: #fff;
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 2px;
  transition: opacity 0.2s;
  border-radius: 6px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.05);
}
.cube-tip[data-dir="top"] .cube-tip-angle,
.cube-tip[data-dir="bottom"] .cube-tip-angle {
  left: 40%;
  transform: translateX(-50%);
}
.cube-picker-choose > [data-action="confirm"] {
  color: rgba(64, 129, 214, 1);
}
</style>

<style lang="scss" scoped>
@import "~styles/index";
.section-color {
  font-weight: 600;
  color: rgba(255, 137, 100, 1);
  span {
    width: px2rem(140px);
    display: inline-block;
    font-weight: 600;
  }
}
.statistics-width {
  width: px2rem(145px);
  display: inline-block;
}
.iconfont {
  color: #999;
  margin-top: px2rem(30px);
  font-size: px2rem(20px);
  img {
    width: px2rem(23px);
    height: px2rem(23px);
    padding-right: px2rem(10px);
  }
}
.screen {
  width: px2rem(690px);
  display: flex;
  align-content: flex-start;
  border: px2rem(20px) solid rgba(240, 240, 240, 1);
  border-left: px2rem(30px) solid rgba(240, 240, 240, 1);
  border-right: px2rem(30px) solid rgba(240, 240, 240, 1);
  overflow: hidden;
  background: rgba(240, 240, 240, 1);
}
.date-width {
  width: px2rem(272px);
  float: left;
  height: px2rem(60px);
}
.date-btn {
  width: px2rem(131px);
  height: px2rem(60px);
  font-size: px2rem(28px);
  border-radius: px2rem(6px);
  margin-left: px2rem(10px);
  border: 1px solid rgba(228, 228, 228, 1);
}
.screen-date {
  border: 1px solid rgba(228, 228, 228, 1);
  width: px2rem(547px);
  border-radius: px2rem(6px);
  position: relative;
  i {
    width: px2rem(1px);
    height: px2rem(25px);
    position: absolute;
    color: #e4e4e4;
    top: px2rem(20px);
    left: 49%;
    font-style: normal;
  }
}
.statistics {
  li {
    display: flex;
    justify-content: space-between;
    color: rgba(54, 54, 54, 1);
    height: px2rem(50px);
    line-height: px2rem(50px);
    padding: 0 px2rem(30px);
    font-size: px2rem(28px);
  }
  li:nth-child(odd) {
    background: rgba(240, 240, 240, 1);
  }
  span {
    text-align: center;
  }
}
.pay {
  i {
    font-style: normal;
    color: rgba(153, 153, 153, 1);
  }
  li {
    display: flex;
    align-content: flex-start;
    padding: 0 px2rem(30px);
    color: rgba(54, 54, 54, 1);
    text-align: left;
    padding-bottom: px2rem(15px);
  }
  span {
    padding-right: px2rem(84px);
  }
}
.chart-box {
  width: px2rem(720px);
  display: inline-block;
  height: px2rem(556px);
}
.chart {
  position: absolute;
  top: px2rem(96px);
  left: px2rem(0px);
  width: px2rem(720px);
}
.chart-h5-wrap {
  min-height: 100%;
  background: #fff;
  text-align: center;
  color: #363636;
  font-size: px2rem(28px);

  .section-title {
    height: px2rem(90px);
    line-height: px2rem(90px);
    font-size: px2rem(28px);
    text-align: left;
    padding-left: px2rem(30px);
    font-weight: bold;
  }
  .list-box li {
    height: px2rem(90px);
    display: flex;
    justify-content: space-between;
    padding: 0 px2rem(30px);
    line-height: px2rem(90px);
    border-bottom: 1px solid #ededed;
    color: rgba(54, 54, 54, 1);
    span:nth-child(2) {
      text-align: left;
    }
  }
}

.page-tittle-wrap {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  background: #fff;
  z-index: 99999;
  width: 100%;
  top: 0;
  left: 0;
}

.chart-tab {
  border-bottom: solid 1px #ededed;
  background: #fff;
  .active {
    border-bottom: solid px2rem(4px) #4081d6;
  }

  &-item {
    width: 22%;
    height: px2rem(88px);
    line-height: px2rem(88px);
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    font-size: px2rem(28px);
    color: #363636;
    cursor: pointer;

    &:first-child {
      margin-left: px2rem(-16px);
    }

    &:last-child {
      margin-right: px2rem(-16px);
    }
  }

  @media screen and (max-width: 360px) {
    &-item {
      font-size: px2rem(26px);
    }
  }
}
.page-tittle {
  font-size: px2rem(36px);
  height: px2rem(100px);
  line-height: px2rem(100px);
  position: relative;
  img {
    width: px2rem(42px);
    height: px2rem(30px);
    left: px2rem(30px);
    top: px2rem(30px);
    position: absolute;
  }
}
.section-box {
  width: px2rem(720);
  margin-left: px2rem(30px);
}
.fill-block {
  height: px2rem(20px);
  background: rgba(240, 240, 240, 1);
}
.chart-body {
  text-align: center;
  font-size: px2rem(24px);
  text-align: left;
  .section {
    min-height: 20px;
    width: px2rem(720);
    margin-left: px2rem(30px);
    ul {
      padding: px2rem(30px) 0 px2rem(15px) 0;
      li {
        padding-bottom: px2rem(15px);
        font-size: px2rem(28px);
      }
    }
  }
  .chart-title {
    height: px2rem(90px);
    line-height: px2rem(95px);
    font-size: px2rem(24px);
    border-bottom: 1px solid #ededed;
    display: flex;
    justify-content: flex-start;
    position: relative;
    img {
      width: px2rem(34px);
      height: px2rem(34px);
      margin-top: px2rem(26px);
      float: left;
    }
    h1 {
      font-size: px2rem(28px);
      color: rgba(64, 129, 214, 1);
      padding: 0 px2rem(15px);
    }
    span {
      position: absolute;
      right: px2rem(50px);
      top: px2rem(2px);
      top: px2rem(0px);
      color: rgba(153, 153, 153, 1);
      img {
        width: px2rem(13px);
        height: px2rem(24px);
        position: absolute;
        right: -9px;
        top: px2rem(7px);
      }
    }
  }
}
.summary {
  margin-top: px2rem(66px);

  span {
    display: inline-block;
    width: 100%;

    &:first-child {
      color: #757575;
      font-size: px2rem(24px);
      margin-bottom: px2rem(15px);
    }

    &:nth-child(2) {
      font-size: px2rem(64px);
      color: #fc4c5a;
      margin-bottom: px2rem(40px);

      &:before {
        content: "\00A5"; /* ￥符号 */
        margin-right: px2rem(8px);
        font-size: px2rem(45px);
      }
    }
  }
}

#e-chart-reports {
  width: 100%;
  min-height: px2rem(550px);
}
</style>
<style lang="scss" scoped>
@import "~styles/index";
.loading {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.section-map {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  text-align: left;
  font-weight: bold;
  font-size: px2rem(28px);
  position: relative;
  border-bottom: 1px solid #ededed;
  display: inline-block;
  margin: 0 px2rem(15px);
  width: 90%;
  i {
    font-style: normal;
  }
}
.title-top {
  padding-top: px2rem(100px);
}
.sub-box {
  text-align: left;
  padding: px2rem(30px);
  line-height: px2rem(30px);
  li {
    span {
      width: px2rem(140px);
      display: inline-block;
    }
  }
  & li:first-child {
    margin-bottom: px2rem(15px);
  }
}
.toolip-icon {
  width: px2rem(34px);
  height: px2rem(34px);
  margin-top: px2rem(30px);
  position: absolute;
  padding-left: px2rem(10px);
}
.business-box {
  z-index: 9999;
  background: #fff;
  width: px2rem(750px);
  height: auto;
  position: absolute;
  top: px2rem(-90px);
  left: px2rem(-30px);
}
.statistics {
  font-size: px2rem(28px);
  li {
    display: flex;
    justify-content: space-between;
    color: rgba(54, 54, 54, 1);
    height: px2rem(50px);
    line-height: px2rem(50px);
    padding: 0 px2rem(30px);
    font-size: px2rem(28px);
  }
  li:nth-child(odd) {
    background: rgba(240, 240, 240, 1);
  }
  span {
    text-align: center;
  }
}
.pay {
  font-size: px2rem(28px);
  i {
    font-style: normal;
    color: rgba(153, 153, 153, 1);
  }
  li {
    display: flex;
    align-content: flex-start;
    padding: 0 px2rem(30px);
    color: rgba(54, 54, 54, 1);
    text-align: left;
    padding-bottom: px2rem(15px);
  }
  span {
    padding-right: px2rem(124px);
  }
}
.chart {
  position: absolute;
  top: px2rem(256px);
  left: px2rem(0px);
  width: px2rem(720px);
}

.scroll-list-wrap {
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
  overflow: hidden;
}

.chart-h5-wrap {
  min-height: 100%;
  background: rgba(240, 240, 240, 1);
  text-align: center;
  color: #363636;
  position: absolute;
  font-size: px2rem(28px);
  width: px2rem(750px);
  .no-more {
    color: rgba(153, 153, 153, 1);
    padding-top: px2rem(40px);
  }
  .list-box li {
    height: px2rem(90px);
    display: flex;
    justify-content: space-between;
    padding-right: px2rem(30px);
    line-height: px2rem(90px);
    // border-bottom: px2rem(1px) solid rgba(228, 228, 228, 0.7);
    // filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#19000000, endcolorstr=#19000000);
    border-bottom: 1px solid #ededed;
    color: rgba(54, 54, 54, 1);
    position: relative;
    span:nth-child(2) {
      text-align: right;
      width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: px2rem(30px);
      i {
        font-style: normal;
        font-size: px2rem(28px);
        color: rgba(153, 153, 153, 1) !important;
      }
    }
    &:nth-child(2) {
      color: #4081d6;
    }
    img {
      width: px2rem(13px);
      height: px2rem(24px);
      right: px2rem(30px);
      top: px2rem(30px);
      position: absolute;
    }
  }
}

.section-title {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  text-align: left;
  // padding-left: px2rem(30px);
  // font-weight: bold;
  font-size: px2rem(28px);
  i {
    font-style: normal;
  }
}
.page-tittle-wrap {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(28px);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 999;
  img {
    width: px2rem(42px);
    height: px2rem(30px);
    left: px2rem(30px);
    top: px2rem(30px);
    position: absolute;
  }
}

.page-tittle {
  font-size: px2rem(36px);
  height: px2rem(100px);
  line-height: px2rem(100px);
}
.section-box {
  width: px2rem(720);
  margin-left: px2rem(30px);
}
.fill-block {
  height: px2rem(20px);
  background: rgba(240, 240, 240, 1);
}
.chart-body {
  text-align: center;
  font-size: px2rem(24px);
  text-align: left;
  .section {
    min-height: 20px;
    width: px2rem(720);
    margin-left: px2rem(30px);
    ul {
      padding: px2rem(30px) 0 px2rem(15px) 0;
      li {
        padding-bottom: px2rem(15px);
      }
    }
  }
}
.summary {
  margin-top: px2rem(66px);

  span {
    display: inline-block;
    width: 100%;

    &:first-child {
      color: #757575;
      font-size: px2rem(24px);
      margin-bottom: px2rem(15px);
    }

    &:nth-child(2) {
      font-size: px2rem(64px);
      color: #fc4c5a;
      margin-bottom: px2rem(40px);

      &:before {
        content: "\00A5"; /* ￥符号 */
        margin-right: px2rem(8px);
        font-size: px2rem(45px);
      }
    }
  }
}

#e-chart-reports {
  width: 100%;
  min-height: px2rem(550px);
}
</style>
