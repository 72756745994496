<template>
  <div>
    <div class="chart-h5-wrap">
      <!-- v-if="!!resData" -->
      <div style='background:#fff;'>
        <div v-if="!hideNavBarFlag" class="page-tittle-wrap">
          <img
            @click="confirmClose"
            src="@/assets/images/dealer/backward.png"
            alt=""
          >技师业绩统计</div>
        <div :class="{'title-top': !hideNavBarFlag}">
          <div class="section-map">
            {{detailData.name}}
            <span><i class="toal">工时总额:</i>¥{{detailData.taskAmount | money}}</span>
          </div>
        </div>
        <div class="fill-block"></div>
        <div class="section-box">
          <ul class="list-box">
            <li
              v-for="(item , index) in resData"
              :key="index"
              @click="goAppOrderDetailPage(item.id)"
            >
              <div class="row">
                <span>{{item.carCode}}<i v-if="item.carCode">&nbsp;&nbsp;</i>{{item.name || ''}}</span><span>￥{{item.taskAmount | money}}</span>
              </div>
              <div class="row">
                <span class="overflow-ellipsis">{{`${item.brandName || '' } ${item.modelName || ''} ${item.modelYear || '' } ${item.displacement || '' }`}}</span>
                <span>{{item.createTime?item.createTime.substring(0,10) : '' }}</span>
              </div>
              <img
                src="@/assets/images/dealer/arrow.png"
                alt=""
              >
            </li>
          </ul>
        </div>
      </div>
      <p class="no-more">没有更多了</p>
    </div>
    <cube-loading
      :size="28"
      v-if="loadingFlag"
      class="loading"
    ></cube-loading>
  </div>
</template>

<script>
import { setSessionStorage } from 'utils/store'
import { getTechniciansStatisticsDetail } from 'api/dealer'
import moment from 'moment'
export default {
  data () {
    return {
      more: false,
      direction: '',
      tipStyle: '',
      eChart: null,
      showBlankChart: true,
      chartsData: [],
      resData: null,
      currentItem: {
        index: 0,
        date: '',
        amount: ''
      },
      listChartMoney: [],
      loadingFlag: true,
      page: {
        pageNum: 1,
        pageSize: 20
      },
      totalCount: 0
    }
  },
  props: {
    detailData: {
      type: Object,
      default: () => {return {}}
    },
    chartType: {
      type: String,
      default: ''
    },
    hideNavBarFlag: {
      type: Boolean,
      default: false
    },
    requestParams: {
      type: Object,
      default: () => {return {}}
    }

  },
  methods: {
    confirmClose () {
      this.$emit('chart-close')
    },
    showTip (direction) {
      this.direction = direction
      this.$refs.tip2.show()
      switch (direction) {
        case 'top':
          this.tipStyle = 'left: -10px; top: 40px;'
          break
        case 'bottom':
          this.tipStyle = 'left: 3%; top: -50px;'
          break
        case 'left':
          this.tipStyle = 'left: 200px; top: -10px;'
          break
        case 'right':
          this.tipStyle = 'left: 2px; top: -10px;'
          break
      }
    },
    close () {
      console.log('click close button')
    },
    clickHandler () {
      console.log('click tip area')
    },
    formatDate (time, format = 'MM-DD') {
      if (parseInt(this.chartType) === 5) {
        format = 'YYYY-MM'
      }
      let date = moment(new Date(time)).format(format)
      return (date === 'Invalid date') ? '' : date
    },
    formatDay (date) {
      let format = 'MM-DD'
      return this.formatDate(date, format)
    },
    formatMonth (month) {
      return this.formatDate(month, 'MM-DD')
    },
    initData () {
      let token = this.$route.query['token']
      setSessionStorage('token', token)
    },
    // 获取技师详情
    getTechniciansStatisticsDetail (morePageFlag) {
      let page = Object.assign({}, this.page)

      if (morePageFlag && (this.page.pageSize * this.page.pageNum < this.totalCount)) {
        page.pageNum = page.pageNum + 1
      }
      this.loadingFlag = true
      const param = Object.assign({}, { technicianId: this.detailData.technicianId }, this.requestParams)
      getTechniciansStatisticsDetail(param, page).then((res) => {
        this.loadingFlag = false
        if (res) {
          if (res.data.result !== 0) return
          if (res.data.data.pageNum === 1) {
            this.resData = res.data.data.page
          } else if (res.data.data.pageNum === this.page.pageNum + 1) {
            this.resData = this.resData.concat(res.data.data.page)
          }
          this.page.pageNum = res.data.data.pageNum
          this.totalCount = res.data.data.totalCount
        }
      })
    },
    // 点击进入APP工单详情
    goAppOrderDetailPage (orderId) {
      if (this.$route.query['type'] === 'Android') {
        // eslint-disable-next-line
        JsToAndroid.goOrderDetail(String(orderId))
      } else if (this.$route.query['type'] === 'ios') {
        // eslint-disable-next-line
        window.webkit.messageHandlers.goOrderDetail.postMessage({ id: orderId })
      }
    },
    refresh () {
      console.log('技师统计详情刷新')
      this.getTechniciansStatisticsDetail(true)
    }
  },
  mounted () {
    if (this.initData) {
      this.initData()
    }
    this.getTechniciansStatisticsDetail()
  }
}
</script>
<style lang="scss" scoped>
@import "~styles/index";
.loading {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.toal {
  width: px2rem(140px);
  display: inline-block;
}
.section-map {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  text-align: left;
  font-size: px2rem(28px);
  position: relative;
  border-bottom: 1px solid #ededed;
  display: inline-block;
  margin: 0 px2rem(15px);
  width: 90%;
  i {
    font-style: normal;
  }
}
.title-top {
  padding-top: px2rem(100px);
}
.sub-box {
  text-align: left;
  padding: px2rem(30px);
  line-height: px2rem(30px);

  & li:first-child {
    margin-bottom: px2rem(15px);
  }
}
.business-box {
  z-index: 9999;
  background: #fff;
  width: px2rem(750px);
  height: auto;
  position: absolute;
  top: px2rem(-90px);
  left: px2rem(-30px);
}
.statistics {
  font-size: px2rem(28px);
  li {
    display: flex;
    justify-content: space-between;
    color: rgba(54, 54, 54, 1);
    height: px2rem(50px);
    line-height: px2rem(50px);
    padding: 0 px2rem(30px);
    font-size: px2rem(28px);
  }
  li:nth-child(odd) {
    background: rgba(240, 240, 240, 1);
  }
  span {
    text-align: center;
  }
}
.pay {
  font-size: px2rem(28px);
  i {
    font-style: normal;
    color: rgba(153, 153, 153, 1);
  }
  li {
    display: flex;
    align-content: flex-start;
    padding: 0 px2rem(30px);
    color: rgba(54, 54, 54, 1);
    text-align: left;
    padding-bottom: px2rem(15px);
  }
  span {
    padding-right: px2rem(124px);
  }
}
.chart-box {
  width: px2rem(720px);
  display: inline-block;
  height: px2rem(636px);
  .chart-text {
    font-size: px2rem(20px);
    color: rgba(153, 153, 153, 1);
    padding: px2rem(28px) px2rem(30px);
    display: flex;
    justify-content: space-between;
  }
}
.chart {
  position: absolute;
  top: px2rem(256px);
  left: px2rem(0px);
  width: px2rem(720px);
}
.chart-h5-wrap {
  min-height: 100%;
  background: rgba(240, 240, 240, 1);
  text-align: center;
  color: #363636;
  position: absolute;
  font-size: px2rem(28px);
  width: px2rem(750px);
  .no-more {
    color: rgba(153, 153, 153, 1);
    padding: px2rem(20px) 0;
  }
  .list-box li {
    height: px2rem(120px);
    padding-top: px2rem(30px);
    box-sizing: border-box;
    position: relative;
    padding-right: px2rem(30px);
    // border-bottom: px2rem(1px) solid rgba(228, 228, 228, 0.7);
    // filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#19000000, endcolorstr=#19000000);
    border-bottom: 1px solid #ededed;
    color: rgba(54, 54, 54, 1);
    span:nth-child(2) {
      text-align: right;
      width: 30%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: px2rem(40px);
      i {
        font-style: normal;
        font-size: px2rem(28px);
        color: rgba(153, 153, 153, 1) !important;
      }
    }
    &:nth-child(2) {
      color: #4081d6;
    }
    .row {
      display: flex;
      justify-content: space-between;

      &:nth-child(1) {
        font-size: px2rem(28px);
        color: #363636;
      }
      &:nth-child(2) {
        font-size: px2rem(24px);
        margin-top: px2rem(10px);
        color: #999999;
      }
    }
    img {
      position: absolute;
      right: px2rem(30px);
      top: px2rem(45px);
      transform: translate(-50%);
      width: px2rem(13px);
      height: px2rem(24px);
    }
  }
}

.section-title {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  text-align: left;
  // padding-left: px2rem(30px);
  font-weight: bold;
  font-size: px2rem(28px);
  i {
    font-style: normal;
  }
}
.page-tittle-wrap {
  height: px2rem(90px);
  line-height: px2rem(90px);
  font-size: px2rem(32px);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 999;
  img {
    width: px2rem(42px);
    height: px2rem(30px);
    left: px2rem(30px);
    top: px2rem(30px);
    position: absolute;
  }
}

.page-tittle {
  font-size: px2rem(36px);
  height: px2rem(100px);
  line-height: px2rem(100px);
}
.section-box {
  width: px2rem(720);
  margin-left: px2rem(30px);
}
.fill-block {
  height: px2rem(20px);
  background: rgba(240, 240, 240, 1);
}
.chart-body {
  text-align: center;
  font-size: px2rem(24px);
  text-align: left;
  .section {
    min-height: 20px;
    width: px2rem(720);
    margin-left: px2rem(30px);
    ul {
      padding: px2rem(30px) 0 px2rem(15px) 0;
      li {
        padding-bottom: px2rem(15px);
      }
    }
  }
}
.summary {
  margin-top: px2rem(66px);

  span {
    display: inline-block;
    width: 100%;

    &:first-child {
      color: #757575;
      font-size: px2rem(24px);
      margin-bottom: px2rem(15px);
    }

    &:nth-child(2) {
      font-size: px2rem(64px);
      color: #fc4c5a;
      margin-bottom: px2rem(40px);

      &:before {
        content: "\00A5"; /* ￥符号 */
        margin-right: px2rem(8px);
        font-size: px2rem(45px);
      }
    }
  }
}

#e-chart-reports {
  width: 100%;
  min-height: px2rem(550px);
}
.overflow-ellipsis {
  width: px2rem(400px);
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
</style>
